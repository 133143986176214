<script setup lang="ts">
    import { type AuthenticationQuery, useAuthenticationQuery } from '@/composables/authentication';
    import router from '@/router';
    import { Navigation } from '@/router/navigation';
    import LoginSignup from '@/components/login/LoginSignup.vue';

    const authenticationQuery: AuthenticationQuery = useAuthenticationQuery();

    const isUserLoggedIn = authenticationQuery.isUserLoggedIn;

    async function navigateAfterLogin(): Promise<void> {
        await router.push({ name: Navigation.Landing });
    }

    async function forgotPassword(): Promise<void> {
        await router.push({ name: Navigation.ForgotPassword });
    }

    async function redirectToSignupSelection(): Promise<void> {
        await router.push({ name: Navigation.SignupSelection });
    }
</script>

<template>
    <div v-show="!isUserLoggedIn" class="flex-container">
        <LoginSignup
            :basket-login="false"
            @login-success="navigateAfterLogin"
            @forgot-password="forgotPassword"
            @signup="redirectToSignupSelection"
        />
    </div>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .flex-container {
        display: flex;
        row-gap: main.$spacing-4;
        flex-direction: column;
        background: main.$vt-c-white;
    }

    @include main.for-breakpoint-lg {
        .flex-container {
            padding: main.$spacing-6;
        }
    }

    .register-container {
        text-align: center;
    }
</style>
